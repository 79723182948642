<template>
  <v-navigation-drawer
    :value="isAddUpdateEventSidebarActive"
    temporary
    touchless
    floating
    stateless
    :right="!$vuetify.rtl"
    :width="$vuetify.breakpoint.smAndUp ? 500 : '100%'"
    app
    @input="val => $emit('update:is-add-update-event-sidebar-active', val)"
  >
    <v-sheet :color="isDark ? '#363351' : '#fafafa'" class="pa-3 d-flex align-center justify-space-between">
      <div class="d-flex align-center">
        <v-avatar size="48" color="secondary">
          <v-icon size="24" class="white--text">
            {{ formIcon }}
          </v-icon>
        </v-avatar>
        <div class="text-h6 font-weight-medium ml-3">
          {{ formTitle }}
        </div>
      </div>
      <v-btn icon x-small @click="$emit('update:is-add-update-event-sidebar-active', false)">
        <v-icon> {{ icons.mdiClose }}</v-icon>
      </v-btn>
    </v-sheet>

    <form>
      <validation-observer ref="observer">
        <validation-provider v-slot="{ errors }" tag="div" mode="eager" name="Durum" rules="required">
          <v-select
            v-model="eventData.is_active"
            label="Durum"
            :items="$store.state.tanimlar.durum"
            class="rounded-0"
            :dark="isDark"
            :menu-props="{ bottom: true, offsetY: true }"
            :error-messages="errors"
            filled
            hide-details
          ></v-select>
        </validation-provider>

        <!-- Sıra No -->
        <validation-provider v-slot="{ errors }" tag="div" mode="eager" name="Başlık" rules="required|max:500">
          <v-text-field
            v-model="eventData.seq"
            filled
            :dark="isDark"
            label="Sıra Numarası"
            :error-messages="errors"
            hide-details
            class="rounded-0"
          ></v-text-field>
        </validation-provider>

        <!-- Başlık -->
        <validation-provider v-slot="{ errors }" tag="div" mode="eager" name="Başlık" rules="required|max:500">
          <v-text-field
            v-model="eventData.baslik"
            filled
            :dark="isDark"
            label="Başlık"
            :error-messages="errors"
            hide-details
            class="rounded-0"
          ></v-text-field>
        </validation-provider>

        <!-- İçerik -->
        <validation-provider v-slot="{ errors }" tag="div" mode="eager" name="İçerik" rules="required|max:500">
          <v-textarea
            v-model="eventData.content"
            filled
            :dark="isDark"
            label="İçerik"
            :error-messages="errors"
            hide-details
            class="rounded-0"
          ></v-textarea>
        </validation-provider>
        <v-divider></v-divider>

        <!-- Tarih -->
        <div>
          <v-menu
            v-model="onOff"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                :prepend-icon="icons.mdiCalendar"
                v-model="eventData.create_datetime"
                :label="eventType == 'add' ? 'Tarih' : 'Tarihi Güncelle'"
                hide-details=""
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="eventData.create_datetime"
              counter
              color="secondary"
              no-title
              @input="onOff = false"
            ></v-date-picker>
          </v-menu>
        </div>

        <v-divider></v-divider>
      </validation-observer>
    </form>
    <template v-slot:append>
      <div class="pa-3">
        <v-btn x-large tile block :loading="loading" color="secondary" @click="submit">
          {{ formButtonText }}
        </v-btn>
      </div>
    </template>
  </v-navigation-drawer>
</template>

<script>
import useAppConfig from '@core/@app-config/useAppConfig'
import {
  mdiCalendar,
  mdiCameraOutline,
  mdiClose,
  mdiEye,
  mdiEyeOff,
  mdiFileTree,
  mdiPencil,
  mdiPlusThick,
  mdiUndo,
} from '@mdi/js'
import { ref } from '@vue/composition-api'
import { TiptapVuetify } from 'tiptap-vuetify'
import Vue from 'vue'

export default {
  components: { TiptapVuetify },
  model: {
    prop: 'isAddUpdateEventSidebarActive',
    event: 'update:is-add-update-event-sidebar-active',
  },
  props: {
    isAddUpdateEventSidebarActive: {
      type: Boolean,
      required: true,
    },
    eventType: {
      type: String,
      required: true,
    },
    eventItem: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const { isDark } = useAppConfig()
    const onOff = ref(false)
    const create_datetime = new Date().toISOString().substr(0, 10)

    return {
      isDark,
      onOff,
      create_datetime,

      icons: {
        mdiClose,
        mdiEyeOff,
        mdiEye,
        mdiCameraOutline,
        mdiCalendar,
        mdiPlusThick,
        mdiPencil,
        mdiFileTree,
        mdiUndo,
      },
    }
  },
  data: () => ({
    items: [],
    isLoading: false,
    search: null,
    show: false,
    loading: false,
    kategoriSecim: [],
    dialogKategori: false,
    eventData: {
      is_active: '1',
      baslik: '',
      content: '',
      kategori: 1,
      seq: '',
      create_datetime: '',
    },
    defaultItem: {
      is_active: '1',
      baslik: '',
      content: '',
      kategori: 1,
      seq: '',
      create_datetime: '',
    },
  }),
  computed: {
    formTitle() {
      return this.eventType === 'add' ? 'Haber - Duyuru Ekle' : 'Düzenle'
    },
    formIcon() {
      return this.eventType === 'add' ? this.icons.mdiPlusThick : this.icons.mdiPencil
    },
    formButtonText() {
      return this.eventType === 'add' ? 'Kaydet' : 'Güncelle'
    },
  },
  watch: {
    isAddUpdateEventSidebarActive(val) {
      if (val && this.eventType === 'edit') {
        this.$nextTick(() => {
          this.eventData = {
            is_active: this.eventItem?.is_active,
            baslik: this.eventItem?.baslik,
            content: this.eventItem?.content,
            kategori: 1,
            seq: this.eventItem?.seq,
          }
        })
      }
      if (!val) {
        this.close()
      }
    },
  },

  methods: {
    uploadFile(event) {
      const file = event.target.files[0]
      // Burada dosya yükleme işlemini gerçekleştirin
      console.log('Yüklenen dosya:', file)
    },

    async submit() {
      this.loading = true
      const isValid = await this.$refs.observer.validate()
      if (!isValid) {
        this.loading = false

        Vue.swal({
          title: 'Hata',
          text: 'Lütfen kırımızı alanları doldurun',
          icon: 'error',
          timer: 2000,
          background: '#FF4C51',
          timerProgressBar: true,
          showConfirmButton: false,
        })

        return
      }

      const data = this.eventData

      const { id } = this.eventItem

      const search = ['baslik', 'content']
      const method = 'saveEvent'
      this.$store
        .dispatch('addUpdateMethod', {
          data,
          search,
          method,
          id,
        })
        .then(response => {
          if (response.error) {
            Vue.swal({
              title: 'Hata',
              text: response.msg,
              icon: 'error',
              timer: 2000,
              background: '#FF4C51',
              timerProgressBar: true,
              showConfirmButton: false,
            })
          } else {
            this.$emit('refetch-data')
            this.$emit('update:is-add-update-event-sidebar-active', false)

            this.$emit('save-event-event', {
              message:
                this.eventType === 'add'
                  ? `${this.eventData.baslik} Duyurusu Eklendi`
                  : `${this.eventData.baslik} Duyurusu Düzenlendi`,
              color: 'success',
              timeout: 5000,
            })
            this.loading = false
          }
        })
        .catch(e => {
          Vue.swal({
            title: 'Hata',
            text: e.message,
            width: '350px',
            icon: 'error',
            background: '#FF4C51',
            showCloseButton: false,
            showConfirmButton: true,
            confirmButtonColor: '#cc3d41',
          })
          this.$emit('update:is-add-update-event-sidebar-active', false)

          this.loading = false
        })
    },
    close() {
      this.$refs.observer.reset()
      this.$nextTick(() => {
        this.eventData = { ...this.defaultItem }
      })
    },
  },
}
</script>
<style lang="scss">
.tiptap-vuetify-editor {
  .v-card {
    box-shadow: none !important;
  }
}
.padded-div {
  margin: 12px;
}
</style>
